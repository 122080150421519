.header-structure {
  display: flex;
  padding: 16px 64px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);;
}
.logoWithTabs{
    display: flex;
    gap: 60px;
    align-items: center;
}

.border{
    border: 1px solid black;
}
