.fw-100 {
  font-weight: 100 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.d-flex {
  display: flex !important;
}

.active {
  border-bottom: 2px solid #0096ff !important;
  border-radius: 0 !important;
  color: #0096ff !important;
}

.ck-editor__editable {
  height: 30vh !important;
}

/* Add this CSS to your stylesheet */
.ck-powered-by {
  display: none;
}

/* .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  height: 350px !important;
} */

/* Global.css or App.css */
/* Hide the spinner for Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #0096ff;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 150, 255, 0.5);
}

.root_div {
  height: calc(100%- 74px) !important;
  overflow-y: auto;
}

.root_div_1 {
  height: 100% !important;
  overflow-y: auto;
}

.react-pdf__Page__textContent {
  display: none !important;
  height: 0 !important;
}

.react-pdf__Page__annotations {
  display: none !important;
}

/* .MuiTablePagination-selectLabel {
  display: none !important;
}

.MuiTablePagination-select {
  display: none !important;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  display: none !important;
} */

.cover-letter-content p {
  margin: 0;
}

/* .activity-card {
  height: 45vh;
  overflow-y: scroll;
} */

/* For extra-large screens */
/* @media (min-width: 1600px) {
  .activity-card {
    height: 58vh; */
/* Adjust this value as needed
  }
}

.activity-box:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  cursor: pointer;
}

.activity-box-candidate {
  overflow-y: scroll !important;
  height: 62vh !important;
}

@media (min-width: 1600px) {
  .activity-box-candidate {
    height: 72vh !important;
    /* Adjust this value as needed */
/* }
} */

/* When activity-box is hovered, apply hover styles to the child icons */
.activity-box:hover .activity-icon-work-history-icon {
  background-color: #4caf50;
  color: white;
}

.activity-box:hover .activity-icon-person-add-icon {
  background-color: #ffa93c;
  color: white;
}

.activity-box:hover .activity-icon-person-icon {
  background-color: #2196f3;
  color: white;
}
.activity-box:hover .activity-icon {
  background-color: #2196f3;
  color: white;
}

.logo {
  transition: opacity 0.1s ease-in-out;
}

.hidden {
  opacity: 0;
  position: absolute;
}

.pageWrapper {
  padding: 40px 48px;
  background-color: #fafafa;
}

.activeSidebarMenu {
  background: #3f95ea !important;
}

.chart-wrapper:first-child {
  height: 100% !important;
}

.sunburstChart canvas {
  height: 100% !important;
}

canvas div {
  width: 100% !important;
}

.dashboardCharts canvas {
  width: 100% !important;
}
